/**
 * Created by Joerg on 28.10.2017.
 */



import {Component} from '@angular/core';
import { StateService } from '@uirouter/angular';
import {MnDataset} from "./MnDataset";
import {MnBackend} from "@mn/core";


@Component({
    selector: 'ct-search-test',
    template: `
        <div mnRow class="mn-layout-col">
            <div mnCol>
                <ct-search style="border-top: 1px solid darkgrey; width: 100%; height: 100%;" (search)="onSearch($event)" [testhook]="testHookBound">
                // empty       
                </ct-search>
            </div>
            <div mnCol [innerHtml]="mResults | mnjson">
            </div>
        </div>
    `
})

export class CtSearchTest {

    mResults:any = {};

    constructor(private mState:StateService, private mBackend:MnBackend) {}

    onSearch(dsid) {
        //console.log(dsid);
        //this.mState.go('app.results',{dsid: dsid, context: 'chemtunes'});
        let ds:MnDataset = new MnDataset(this.mBackend,dsid,"chemtunes");
        ds.subscribe((status) => {
            //console.log(status);
            this.mResults = ds.raw();
        })
    }

    public testHookBound: Function;

    public ngOnInit(){
        this.testHookBound = this.testHook.bind(this);
    }

    public testHook(query:any):any {
        return query;
        /*console.log(JSON.stringify(query,null,2));

        let test_query = {
            "databases": [
                "ChemTunes"
            ],
            "chem": {
                "active": true,
                "datasetid": "",
                "terms": "50-00-0",
                "mode": "term",
                "structure": {
                    "key": "partial",
                    "value": 50
                },
                "term": {
                    "key": "partial"
                }
            },
            "reg": {
                "active": true, // <----------------------------- comment me in or out
                "Compound/Annotation/Inventory": {
                 "standard": [],
                 "negate": false
                 },
                 "Compound/Annotation/Use Type": {
                 "standard": [],
                 "negate": false
                 },
                 "Compound/Annotation/Use Function": {
                 "standard": [],
                 "negate": false
                 }
            },
            "tox": {
                "active": true,
                 "study": []
            }
        };

        return test_query;*/
    }
}