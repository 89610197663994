/**
 * Created by joerg on 7/17/17.
 */

// rxjs
// rxjs
import 'rxjs/Rx';
import {Subscription,Subject,BehaviorSubject} from 'rxjs/Rx';


// angular
import {Injectable, Component,Input,HostListener,Output,EventEmitter,Optional} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode,CtSearchNodeParams,CtSearchNodeMeta} from './CtSearchNode';

/*
 *
 {
 "databases": ["REPDOSE", "ETOX_2016_3"],
 "reg": {
 "active": true,
 "compound/annotation/inventory"   : {"standard": [], "negate": false},
 "compound/annotation/use type"    : {"standard": [], "negate": false},
 "compound/annotation/use function": {"standard": [], "negate": false}
 }
 }
 * */


export interface CtSearchSelectionData {
    rows: string[];
    selection: string[];
    title: string;
    theme: string;
    tree: boolean;
    startfrom:boolean;
}

@Injectable()
export class CtSearchSelectionService {

    private mUpdateSubject:BehaviorSubject< CtSearchSelectionData > =  new BehaviorSubject(null);
    private mSelectionSubject:Subject< string[] > =  new Subject();
    private mSelectionStartFromSubject:Subject< string[] > =  new Subject();
    private mBlockSubject:Subject< boolean > =  new Subject();

    constructor() {

    }

    public update(data:CtSearchSelectionData) {
        this.mUpdateSubject.next(data);
    }

    public onUpdate(next?: (value: CtSearchSelectionData) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mUpdateSubject.subscribe(next,error,complete);
    }

    public selectionStartFrom(selection:string[]) {
        this.mSelectionStartFromSubject.next(selection);
    }

    public onSelectionStartFrom(next?: (value: string[]) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mSelectionStartFromSubject.subscribe(next,error,complete);
    }

    public selection(selection:string[]) {
        this.mSelectionSubject.next(selection);
    }

    public onSelection(next?: (value: string[]) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mSelectionSubject.subscribe(next,error,complete);
    }

    public block(block:boolean) {
        this.mBlockSubject.next(block);
    }

    public onBlock(next?: (value: boolean) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mBlockSubject.subscribe(next,error,complete);
    }

}


@Component({
    selector: 'ct-search-selection',
    templateUrl: './CtSearchSelection.html',
    providers: [CtSearchSelectionService]
})
@MnUnsubscribe()
export class CtSearchSelection extends CtSearchNode{

    private mData:CtSearchSelectionData;
    private mOnSelection:Subscription;
    private mGetTerms:Subscription;
    private mGetTermsStartFrom:Subscription;
    private mSelectableItems:number = 0;

    @Input() ident:string;

    constructor(private mSearchService:CtSearchService, private mSelectionService:CtSearchSelectionService) {
        super();
        this.mOnSelection = mSelectionService.onSelection((selection) => this.onSelection(selection));
        this.mData = this.dummy();
    }

    private dummy():CtSearchSelectionData {
        return {
            rows: [],
            selection: [],
            title: "",
            theme: "ag-fresh",
            tree: false,
            startfrom:false
        };
    }

    public validateNode(params:CtSearchNodeParams) {
        //console.log("overwritten invalidate");
        this.mParams = params;
        if (this.mGetTerms) this.mGetTerms.unsubscribe();
        this.mGetTerms = this.mSearchService.getTerms(this.mMeta.url, this.mParams,(rows) => this.onRows(rows));
    }

    public invalidateNode() {
        super.invalidateNode();
        this.setValue([]);
        //this.mData = this.dummy();
        //this.mSelectionService.update(this.mData);
        this.mSelectionService.block(true);
        if (this.mGetTerms) {
            this.mGetTerms.unsubscribe();
        }
        if (this.mGetTermsStartFrom) {
            this.mGetTerms.unsubscribe();
        }
    }

    ngAfterContentInit() {
        //this.mSelectionService.update(this.mData);
    }

    public setNodeMeta(meta:CtSearchNodeMeta) {
        this.mMeta = meta;
    }

    private collectSelection(tree:any[],selection:any[]) {
        for (var i = 0, l = tree.length; i < l; i++) {
            var n = tree[i];
            if (n.value && n.value != null) {
                selection.push(n.value);
            }
            if (n.nodes && n.nodes.length > 0) {
                this.collectSelection(n.nodes,selection);
            }
        }
    }

    private onRows(rows) {
        //console.log(rows);

        if (rows.list) {
            this.mData.rows = rows.list;
            this.mData.tree = false;
        } else if (rows.tree) {
            this.mData.rows = rows.tree;
            this.mData.tree = true;
        } else {
            this.mData.rows = rows;
            this.mData.tree = false;
        }
        //this.mData.selection = this.mMeta.result;
        this.mData.selection = this.getValue();
        this.mData.title = this.mMeta.title;
        this.mData.startfrom = this.mMeta.startfrom || false;

        if (this.mData.tree) {
            let selectables = [];
            this.collectSelection(this.mData.rows,selectables);
            this.mSelectableItems = selectables.length;
        } else {
            this.mSelectableItems = this.mData.rows.length;
        }

        this.mSelectionService.update(this.mData);
        this.mValid = true;
        super.validateChildNodes(this.mParams);
        this.mSelectionService.block(false);
    }

    private onRowsStartFrom(value:string[],rows,trigger:string[],added:boolean) {
        //console.log('startfrom:onRowsStartFrom',rows,trigger,added);
        // we don't handle the tree case here
        if (rows.list) {
            rows = rows.list;
            if (added) {
                for (let i = 0, l = rows.length; i < l; i++) {
                    let r = rows[i];
                    if (value.indexOf(r) < 0) {
                        value.push(r);
                    }
                }
            } else {
                for (let i = 0, l = rows.length; i < l; i++) {
                    let r = rows[i];
                    let vir = value.indexOf(r);
                    if (vir >= 0) {
                        value.splice(vir,1);
                    }
                }
            }
            //console.log("startfrom:onSelection",value);
            //this.mMeta.result = value;
            this.setValue(value);
            this.mData.selection = value;
            this.mSelectionService.selectionStartFrom(value);
            //this.mSelectionService.update(this.mData);
            super.invalidateChildNodes();
            super.validateChildNodes(this.mParams);
        }
    }

    private onSelection(value:string[]) {
        let do_startfrom:boolean = (value.length > 0) && (value.length < this.mSelectableItems);

        if (this.mMeta.startfrom && do_startfrom) {
            //console.log('startfrom:running');
            var added:string[] = [];
            var removed:string[] = [];
            //let r = this.mMeta.result.slice();
            let r = this.getValue().slice();
            for (var i = 0, l = value.length; i < l; i++) {
                let v = value[i];
                let riv = r.indexOf(v);
                if (riv < 0) {
                    added.push(v);
                } else {
                    r.splice(riv,1);
                }
            }
            removed = r;
            if (added.length > 0) {
                this.mSelectionService.block(true);
                this.mSearchService.getTermsStartFrom(this.mMeta.url, this.mParams, added,
                    (rows) => this.onRowsStartFrom(value,rows,added,true),
                    (error) => console.log(error),
                    () => this.mSelectionService.block(false)
                );
            } else if (removed.length > 0) {
                this.mSelectionService.block(true);
                this.mSearchService.getTermsStartFrom(this.mMeta.url, this.mParams, removed,
                    (rows) => this.onRowsStartFrom(value,rows,removed,false),
                    (error) => console.log(error),
                    () => this.mSelectionService.block(false)
                );
            }
            //console.log("startfrom:added",added);
            //console.log("startfrom:removed",removed);
        } else {
            //console.log("onSelection",value);
            //this.mMeta.result = value;
            this.setValue(value);
            //console.log('Query',this.mSearchService.Query);
            super.invalidateChildNodes();
            super.validateChildNodes(this.mParams)
        }
    }

    ngOnDestroy() {
        this.onDestroy();
    }
}
