/**
 * Created by Jongjin on 09/05/17.
 */

//BB comment: This class should be moved to the mnjsme project


// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';

// angular
import {Component, ViewChild, Injectable, Input} from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';

// jsme
import {MnJsme} from "@mn/jsme"

// TODO add translation for Cancel and Submit
// Use a translation directive

@Component({
    selector: 'mn-jsme-dialog',
    template: `<div>
                    <mn-jsme [height]="jsmeHeight" [width]="jsmeWidth" [options]="jsmeOptions" (molChange)="onStructureChange($event)"></mn-jsme>
               </div>
               <div class="buttonSet">
                    <button mat-button  (click)="close(true)" [disabled]="!structureHasChanged">Submit</button>
                    <button mat-button mat-dialog-close (click)="close()" style="margin-right: 5px;">Cancel</button>

               </div>
    `,
    styles:[`
        .buttonSet{
            margin-top: 10px;
            align-items: right;
            text-align: right;
        }
    `]
})
export class MnJsmeDialog {

    mol:string;
    structureHasChanged = false;
    initMolIsRunning = false;


    @ViewChild(MnJsme) public mJsme:MnJsme;

    constructor(public dialogRef: MatDialogRef<MnJsmeDialog>){

    }

    @Input()
    jsmeHeight = 400;

    @Input()
    jsmeWidth = 600;

    @Input()
    jsmeOptions = '';


    /**
     * The submit button is disabled by default and enabled as soon as the structure in the editor has changed.
     * @param event
     */
    onStructureChange(event) {
        //console.log(event);

        if (!this.initMolIsRunning) {

            setTimeout(() => { //prevent ExpressionChangedAfterItHasBeenCheckedError
                this.structureHasChanged = true;
            });
        }
        this.initMolIsRunning = false;
    }

    close(isSubmit:boolean){
        if(isSubmit){
            // console.log('mol  ',this.getMol());
            this.dialogRef.close(this.getMol());
        }else{
            this.dialogRef.close();
        }
    }

    getMol(){
        return this.mJsme.mol2000();
    }

    setMol(mol){
        return this.mJsme.setMol(mol); // will trigger an event onStructureChange
    }

    initMol(mol) {
        this.initMolIsRunning = true;
        this.setMol(mol);
    }

}

// one idea is to keep the same jsme instance => undo would retrieve previous structures

@Injectable()
export class MnJsmeDialogService {

    constructor(public dialog: MatDialog) { }

    public open(): Observable<any> {
        let dialogRef: MatDialogRef<MnJsmeDialog>;
        dialogRef = this.dialog.open(MnJsmeDialog);
        return dialogRef.afterClosed();
    }

    public openExisting(mol:string): Observable<any> {
        let dialogRef: MatDialogRef<MnJsmeDialog>;
        dialogRef = this.dialog.open(MnJsmeDialog);
        setTimeout(() => {
            dialogRef.componentInstance.initMol(mol);
        });
        return dialogRef.afterClosed();
    }

}